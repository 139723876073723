import React from 'react';
import styled from 'styled-components';

import { media } from '../../../styles/media';
import { Colors } from '../../../styles/colors';

const UpdateWrapperInner = styled.div`
  padding: 30px 0 0 0;
`;

export const UpdateWrapper = ({ children }: React.PropsWithChildren<React.HTMLAttributes<HTMLElement>>) => {
  return <UpdateWrapperInner>{children}</UpdateWrapperInner>;
};

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const WrapperLeft = styled.div`
  flex: 400px;
  ${media.from_tablet`
    padding: 0 40px 0 0;
  `}
`;

export const WrapperRight = styled.div`
  flex: 400px;
  ${media.from_tablet`
    padding: 0 40px 0 0;
  `}
`;

export const WrapperInner = styled.div`
  max-width: 600px;
`;

export const WrapperButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 64px;
  max-width: 600px;
`;

export const WrapperUpload = styled.section`
  padding: 5px;
  border: 1px dotted ${Colors.Primary};

  &:hover {
    border: 1px solid ${Colors.Primary};
  }
`;

export const WrapperUploadInfo = styled.div`
  text-align: center;
  cursor: pointer;
`;

export const WrapperUploadFiles = styled.div`
  padding: 5px;
  display: flex;
  flex-wrap: wrap;

  > div {
    margin-right: 20px;
    margin-bottom: 20px;
  }
`;
