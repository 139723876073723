import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import styled from 'styled-components';
import { Controller, useFormContext } from 'react-hook-form';

import { Colors } from '../../../../styles/colors';
import { FieldWrapper } from '../FieldWrapper';
import { IconButton } from '../../Icons/Icon';
import { ChevronRightIcon, ChevronLeftIcon, CloseIcon, SparklingIcon } from '../../Icons';
import { PrimaryButton } from '../../Button';
import { TinyLoader } from '../../Loader';
import { Spacer } from '../../Spacer/Spacer';
import { Copy } from '../../Typography';

const LETTER_LEFT = 120;

export const SmartTextInput = ({
  name,
  noMargin,
  defaultValue,
  onSmart,
  tips,
  minLetters = LETTER_LEFT,
  ...rest
}: SmartTextInputProps) => {
  const { control, setValue, setError, clearErrors } = useFormContext();
  const [proposedValue, setProposedValue] = React.useState<string>('');
  const [hasError, setHasError] = React.useState<boolean>(false);
  const [isAiLoading, setIsAiLoading] = React.useState<boolean>(false);
  const [currentProposedIndex, setCurrentProposedIndex] = React.useState<number>(1);

  const handleSmart = async (fieldName: string, initialValue: string) => {
    if (!initialValue && minLetters > 0) {
      setHasError(true);
      setError(fieldName, { message: 'Please enter a value to generate an suggestion from AI.' });
      return;
    }
    if (initialValue.length < minLetters && minLetters > 0) {
      setHasError(true);
      setError(fieldName, {
        message: `Please enter at least ${minLetters} characters to generate a suggestion from AI.`,
      });
      return;
    }
    setIsAiLoading(true);
    const result = await onSmart(initialValue);
    setIsAiLoading(false);
    setProposedValue(result);
  };

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue || ''}
      render={({ field, fieldState }) => (
        <FieldWrapper noMargin={noMargin} tips={tips}>
          {/* <pre>{JSON.stringify({ fieldState }, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify({ field }, null, 2)}</pre> */}
          <SmartFieldWrapper>
            <TextFieldExtended
              {...field}
              fullWidth
              {...rest}
              helperText={
                fieldState.error ? (
                  <ErrorMessage>{fieldState.error.message}</ErrorMessage>
                ) : isAiLoading ? (
                  'Our AI is looking for a suggestion. Please wait a few seconds...'
                ) : minLetters - field.value.length <= 0 ? (
                  'Click on the AI Icon to generate a suggestion.'
                ) : (
                  <>
                    <Copy styleLevel={3} color={Colors.BlackLightest}>
                      Compose a brief description of the role you are currently seeking.
                    </Copy>
                    <Copy styleLevel={3} isItalic={true} color={Colors.BlackLightest}>
                      Min {LETTER_LEFT - field.value.length} extra characters needed to enable AI.`
                    </Copy>
                  </>
                )
              }
              onFocus={() => {
                clearErrors(field.name);
              }}
              color={fieldState.error ? 'error' : 'primary'}
            />
            <SmartAction>
              {isAiLoading ? (
                <TinyLoader />
              ) : (
                <IconButton onClick={() => handleSmart(name, field.value)}>
                  {hasError ? <SparklingIcon color={Colors.Contrast} /> : <SparklingIcon />}
                </IconButton>
              )}
            </SmartAction>
          </SmartFieldWrapper>
          {proposedValue && (
            <ProposedValueContainer>
              {proposedValue}
              <Spacer y={8} />
              <ProposedValueActions>
                <PrimaryButton
                  size="tiny"
                  inline={true}
                  onClick={() => {
                    setValue(name, proposedValue);
                    setProposedValue('');
                  }}
                >
                  Use
                </PrimaryButton>
                <ProposedValueNavigation>
                  <ChevronLeftIcon />
                  {currentProposedIndex} / 1
                  <ChevronRightIcon />
                </ProposedValueNavigation>
                <IconButton
                  onClick={() => {
                    setProposedValue('');
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </ProposedValueActions>
            </ProposedValueContainer>
          )}
        </FieldWrapper>
      )}
    />
  );
};

const ErrorMessage = styled.div`
  padding: 0 0 0 8px;
  font-size: 16px;
  color: ${Colors.Error};
`;

const SmartFieldWrapper = styled.div`
  position: relative;
`;

const SmartAction = styled.div`
  position: absolute;
  right: -24px;
  top: -24px;
`;

const ProposedValueContainer = styled.div`
  flex: 1;
  flex-direction: column;
  padding: 8px 8px;
  background-color: ${Colors.ContrastLightest};
`;

const ProposedValueActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ProposedValueNavigation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

type SmartTextInputProps = {
  tips?: React.ReactNode;
  name: string;
  minLetters?: number;
  noMargin?: boolean;
  onSmart: (initialValue: string) => Promise<string>;
} & TextFieldProps;

const TextFieldExtended = styled(TextField)`
  .MuiInputBase-colorError {
    color: ${Colors.Error};
  }

  .Mui-focused {
    fieldset {
      border-left-width: 6px !important; // override inline-style
    }
  }
  .MuiFormHelperText-contained {
    margin: 0;
  }
`;
