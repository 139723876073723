import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationImproveJobDescriptionArgs } from '../../generated/types';

export const IMPROVE_JOB_DESCRIPTION = gql`
  mutation ImproveJobDescription($data: ImproveJobDescriptionData!) {
    improveJobDescription(data: $data)
  }
`;

export const useImproveJobDescription = (
  options?: MutationHookOptions<Pick<Mutation, 'improveJobDescription'>, MutationImproveJobDescriptionArgs>
) =>
  useMutation<Pick<Mutation, 'improveJobDescription'>, MutationImproveJobDescriptionArgs>(IMPROVE_JOB_DESCRIPTION, {
    ...options,
  });
