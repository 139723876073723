import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { SubmitHandler } from 'react-hook-form';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { useRouter } from '../../../hooks/useRouter';
import { useUserContext } from '../../../contexts/UserContext';

import { EducationLevel, EntityType, Industry, InterestLevel, SeniorityLevel } from '../../../generated/types';

import { Colors } from '../../../styles/colors';
import { Urls } from '../../urls';
import { validateSubmitedValues } from '../../../utils/form';
import { boardFormProperties } from '../../../types/board';

import { GET_BOARD, useGetBoard } from '../../../graph/queries/getBoard';
import { useUpdateBoard } from '../../../graph/mutations/updateBoard';
import { useCreateUploadSignedUrl } from '../../../graph/mutations/createUploadSignedUrl';
import { useArchiveFile } from '../../../graph/mutations/archiveFile';
import { useImproveJobDescription } from '../../../graph/mutations/improveJobDescription';

import { Wrapper, WrapperLeft, WrapperRight, WrapperInner, WrapperButtons, UpdateWrapper } from './Update.styles';
import { SkeletonBlock } from '../../../components/atoms/Skeleton/SkeletonBlock';
import { H1 } from '../../../components/atoms/Typography/Headings';
import { Link, PrimaryButton, SecondaryButton } from '../../../components/atoms/Button/Buttons';
import { TextInput } from '../../../components/atoms/Form/TextInput';
import { SelectInput, SelectItem } from '../../../components/atoms/Form/SelectInput/SelectInput';
import { CheckboxInput } from '../../../components/atoms/Form/CheckboxInput';
import { Form } from '../../../components/atoms/Form/Form';
import { File as FileComponent } from '../../../components/atoms/File';
import { Copy } from '../../../components/atoms/Typography';
import { PlacesAutocompleteInput } from '../../../components/atoms/Form/PlacesAutocompleteInput';
import { ChevronRightIcon, CrownIcon } from '../../../components/atoms/Icons';
import { UploadZone } from '../../../components/molecules/UploadZone';
import { AutocompleteMultipleInput } from '../../../components/atoms/Form/AutocompleteInput/AutocompleteMultipleInput';
import { InfoAlert } from '../../../components/atoms/InfoAlert';
import { SmartTextInput } from '../../../components/atoms/Form/SmartTextInput';
import { FreePlanBlock } from '../../../components/atoms/FreePlanBlock';

export const BoardUpdate: React.FC<{}> = () => {
  const { query: { userUuid = '', boardUuid = '' } = {}, navigate, routerState } = useRouter();
  const { data: { board } = {}, loading } = useGetBoard({
    variables: { userUuid, boardUuid },
    fetchPolicy: 'network-only',
  });
  const [updateBoard] = useUpdateBoard();
  const [improveJobDescription] = useImproveJobDescription();
  const [archiveFile, _archiveFileState] = useArchiveFile();
  const [createUploadSignedUrl] = useCreateUploadSignedUrl();
  const [newFiles, setNewFiles] = useState<any[]>([]);
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
  const [coreSkills, setCoreSkills] = useState<string[]>([]);
  const [hasCoreSkills, setHasCoreSkills] = useState<boolean>(false);
  const [jobTitle, setJobTitle] = useState<string>('');
  const [_jobDescription, setJobDescription] = useState<string>('');
  const user = useUserContext();

  useEffect(() => {
    if (!board) {
      return;
    }
    const coreSkills = board?.files?.reduce<string[]>((acc: string[], file) => {
      const fileCoreSkills = (file?.aiCoreSkills || []) as string[];
      if (fileCoreSkills.length > 0) {
        return [...acc, ...fileCoreSkills];
      } else {
        return acc;
      }
    }, []) as string[];

    if (coreSkills.length > 0) {
      setHasCoreSkills(true);
      setCoreSkills(coreSkills);
    } else {
      setCoreSkills(softwareEngineerSkills);
    }
  }, [board]);

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      for (const file of newFiles) {
        const savedFile = await archiveFile({
          variables: {
            entityType: EntityType.Board,
            userUuid,
            boardUuid,
            fileUuid: file.uuid,
            isDeleted: false,
          },
        });
        if (savedFile.errors) {
          throw new Error('Could not save file');
        }
      }

      const formattedFormValues = validateSubmitedValues(data, boardFormProperties);

      const updatedBoard = await updateBoard({
        variables: {
          userUuid,
          boardUuid,
          data: formattedFormValues,
        },
      });
      if (updatedBoard.errors) {
        throw new Error('Could not update board');
      }

      toast.success('Board updated successfully');

      if (routerState?.from) {
        navigate(routerState.from);
      } else {
        navigate(`/${Urls.Boards}`);
      }
    } catch (error) {
      console.error(error);
      toast.error('Sorry we could not update the board. Please reload the page and try again later.');
    }
  };

  async function handleDeleteFileClick(fileUuid: string, event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    await archiveFile({
      variables: {
        entityType: EntityType.Board,
        userUuid,
        boardUuid,
        fileUuid,
        isDeleted: true,
      },
      awaitRefetchQueries: true,
      refetchQueries: [{ query: GET_BOARD, variables: { userUuid, boardUuid } }],
    });
  }

  async function handleFileDrop(file: File): Promise<any> {
    setIsFileUploading(true);

    // Pages from Apple does not have a mimetype.
    // We need to get the mimetype from the file extension.
    let currentMimetype = file.type || '';
    if (!currentMimetype) {
      const fileExtension = file.name.split('.').pop();
      if (fileExtension === 'pages') {
        currentMimetype = 'application/vnd.apple.pages';
      }
    }

    try {
      // Create deleted file in DB with a signed url
      const createUploadSignedUrlResponse = await createUploadSignedUrl({
        variables: {
          entityType: EntityType.Board,
          userUuid,
          boardUuid,
          filename: file.name,
          mimetype: currentMimetype,
          size: file.size,
          lastModified: file.lastModified,
        },
      });
      const { uploadSignedUrl, uuid, mimetype, formattedMimetype } =
        createUploadSignedUrlResponse?.data?.createUploadSignedUrl || {};
      if (!uploadSignedUrl) {
        throw new Error('uploadSignedUrl is empty');
      }
      if (!uuid) {
        throw new Error('uuid is empty');
      }

      // Upload file to S3
      const result = await fetch(uploadSignedUrl, {
        method: 'PUT',
        body: file,
      });

      if (!result.ok) {
        setIsFileUploading(false);
        throw new Error('Could not upload file to s3');
      }

      setIsFileUploading(false);

      return {
        formattedMimetype,
        mimetype,
        uuid,
      };
    } catch (error) {
      setIsFileUploading(false);
      console.error(error);
    }
  }

  let child = <></>;

  if (loading) {
    child = (
      <>
        <SkeletonBlock style={{ height: '60px', marginBottom: '20px' }} />
        <SkeletonBlock style={{ height: '60px', marginBottom: '20px' }} />
        <SkeletonBlock style={{ height: '200px', marginBottom: '40px' }} />
        <SkeletonBlock style={{ height: '100px', marginBottom: '0' }} />
      </>
    );
  } else if (board) {
    const oldFilesList = board?.files?.map(({ filename, mimetype, formattedMimetype, uuid: fileUuid }) => {
      return (
        <FileComponent
          key={fileUuid}
          uuid={fileUuid}
          status="uploaded"
          filename={filename}
          formattedMimetype={formattedMimetype}
          onDeleteClick={(event) => handleDeleteFileClick(fileUuid, event)}
        />
      );
    });
    child = (
      <Form onSubmit={onSubmit} validationSchema={validationSchema}>
        <Wrapper>
          <WrapperLeft>
            <WrapperInner>
              <TextInput
                name="title"
                defaultValue={board.title}
                type="text"
                label="Wanted Job Title"
                placeholder='e.g. "Full Stack Developer"'
                onBlur={(event) => {
                  setJobTitle(event.target.value);
                }}
              />
              {!board.location && (
                <InfoAlert isSmall={true}>
                  Adding your address allow us to calculate the distance to the job opportunities.
                </InfoAlert>
              )}
              <PlacesAutocompleteInput
                defaultValue={board.location}
                name="location"
                label="Location"
                placeholder="Where are you living?"
              />

              <FreePlanBlock>
                <InfoAlert icon={<CrownIcon size={2} />} isSmall={true}>
                  Upgrade to one of our Premium Plans to get AI suggestions on your job description.{' '}
                  <Link to="/checkout">Learn more...</Link>
                </InfoAlert>
              </FreePlanBlock>

              {user?.isMember === true ? (
                <SmartTextInput
                  name="description"
                  defaultValue={board.description}
                  type="textarea"
                  label="Professional Summary"
                  multiline={true}
                  minRows={5}
                  placeholder="Describe your ideal job in a few lines"
                  onSmart={async (initialValue) => {
                    try {
                      const result = await improveJobDescription({
                        variables: {
                          data: {
                            title: jobTitle || board.title,
                            description: initialValue,
                          },
                        },
                      });
                      return result?.data?.improveJobDescription || '';
                    } catch ({ graphQLErrors }: any) {
                      let message =
                        'We apologize, but we were unable to generate a description at this moment. \n\nPlease refresh the page and attempt again later. \n\nShould the issue persist, feel free to contact us for further assistance.';
                      if (graphQLErrors?.[0]?.extensions?.code === 'NO_ENOUGH_CREDITS') {
                        message =
                          'You do not have enough credits to generate a description. You have used all your credits for this month. Contact us to get more credits.';
                      }
                      toast.error(message, {
                        autoClose: 7000,
                      });
                      return '';
                    }
                  }}
                />
              ) : (
                <TextInput
                  name="description"
                  defaultValue={board.description}
                  type="textarea"
                  label="Professional Summary"
                  multiline={true}
                  minRows={5}
                  placeholder="Describe your ideal job in a few lines"
                  onBlur={(event) => {
                    setJobDescription(event.target.value);
                  }}
                />
              )}

              {user?.isMember === true ? (
                <>
                  {!hasCoreSkills && (
                    <InfoAlert isSmall={true}>Upload your CVs to get AI suggestions for your core skills.</InfoAlert>
                  )}
                </>
              ) : (
                <InfoAlert icon={<CrownIcon size={2} />} isSmall={true}>
                  Upgrade to one of our Premium Plans to get AI suggestions on your core skills.{' '}
                  <Link to="/checkout">Learn more...</Link>
                </InfoAlert>
              )}

              <AutocompleteMultipleInput
                options={coreSkills}
                name="coreSkills"
                label="Core Skills"
                defaultValue={board.coreSkills}
              ></AutocompleteMultipleInput>

              <TextInput name="linkedin" defaultValue={board.linkedin} type="url" label="Linkedin" />

              <SelectInput defaultValue={board.industry} name="industry" label="Industry">
                <SelectItem value="">
                  <em>None of these</em>
                </SelectItem>
                <SelectItem value={Industry.AccountingFinance}>Accountind and Finance</SelectItem>
                <SelectItem value={Industry.Administrative}>Administrative</SelectItem>
                <SelectItem value={Industry.ArtDesign}>Art & Design</SelectItem>
                <SelectItem value={Industry.BusinessSales}>Business & Sales</SelectItem>
                <SelectItem value={Industry.Construction}>Construction</SelectItem>
                <SelectItem value={Industry.Education}>Education</SelectItem>
                <SelectItem value={Industry.Engineering}>Engineering</SelectItem>
                <SelectItem value={Industry.Entertainment}>Entertainment</SelectItem>
                <SelectItem value={Industry.GovernmentPublicAdministration}>
                  Government & Public Administration
                </SelectItem>
                <SelectItem value={Industry.HealthcareServices}>Healthcare Services</SelectItem>
                <SelectItem value={Industry.HospitalityTourism}>Hospitality & Tourism</SelectItem>
                <SelectItem value={Industry.HumanResources}>Human Resources</SelectItem>
                <SelectItem value={Industry.InformationTechnology}>Information Technology</SelectItem>
                <SelectItem value={Industry.Legal}>Legal</SelectItem>
                <SelectItem value={Industry.Manufacturing}>Manufacturing</SelectItem>
                <SelectItem value={Industry.RetailCustomerService}>Retail & Customer Service</SelectItem>
                <SelectItem value={Industry.ScienceResearch}>Science & Research</SelectItem>
                <SelectItem value={Industry.SoftwareEngineering}>Software Engineering</SelectItem>
                <SelectItem value={Industry.TransportationLogistics}>Transportation & Logistics</SelectItem>
                <SelectItem value={Industry.Other}>Other</SelectItem>
              </SelectInput>
            </WrapperInner>
          </WrapperLeft>
          <WrapperRight>
            <WrapperInner>
              <TextInput
                name="availableDate"
                defaultValue={board.availableDate}
                type="date"
                InputLabelProps={{ shrink: true }}
                label="When can you start your new job?"
              />
              <SelectInput defaultValue={board.educationLevel} name="educationLevel" label="Highest level of education">
                <SelectItem value="">
                  <em>None of these</em>
                </SelectItem>
                <SelectItem value={EducationLevel.ALevelsGnvq}>A levels /advanced GNVQ</SelectItem>
                <SelectItem value={EducationLevel.Btec}>BTEC</SelectItem>
                <SelectItem value={EducationLevel.CityGuilds}>City &amp; Guilds</SelectItem>
                <SelectItem value={EducationLevel.Diploma}>Diploma</SelectItem>
                <SelectItem value={EducationLevel.GcseGnvqOLevels}>GCSE/GNVQ/O levels</SelectItem>
                <SelectItem value={EducationLevel.HndHnc}>HND/HNC</SelectItem>
                <SelectItem value={EducationLevel.MasterDegreeOrHigher}>Masters degree or higher</SelectItem>
                <SelectItem value={EducationLevel.Phd}>PhD</SelectItem>
                <SelectItem value={EducationLevel.SeniorBusinessTechQualification}>
                  Senior business or technical qualification
                </SelectItem>
                <SelectItem value={EducationLevel.UniversityDegree}>University degree</SelectItem>
              </SelectInput>
              <CheckboxInput
                isChecked={board.workRightUK}
                name="workRightUK"
                label="Eligible to work in the UK"
                noMargin
              />
              <CheckboxInput
                isChecked={board.workRightEU}
                name="workRightEU"
                label="Eligible to work in the EU"
                noMargin
              />
              <SelectInput defaultValue={board.interestLevel} name="interestLevel" label="Interest">
                <SelectItem value="">
                  <em>Select one of these</em>
                </SelectItem>
                <SelectItem value={InterestLevel.JustBrowsing}>Just Browsing</SelectItem>
                <SelectItem value={InterestLevel.OpenToOpportunity}>Open to Opportunity</SelectItem>
                <SelectItem value={InterestLevel.ActivelyLooking}>Actively Looking</SelectItem>
              </SelectInput>
              <SelectInput defaultValue={board.seniorityLevel} name="seniorityLevel" label="Seniority">
                <SelectItem value="">
                  <em>Select one of these</em>
                </SelectItem>
                <SelectItem value={SeniorityLevel.Entry}>Entry</SelectItem>
                <SelectItem value={SeniorityLevel.Junior}>Junior</SelectItem>
                <SelectItem value={SeniorityLevel.Mid}>Mid</SelectItem>
                <SelectItem value={SeniorityLevel.Senior}>Senior</SelectItem>
                <SelectItem value={SeniorityLevel.Lead}>Lead</SelectItem>
              </SelectInput>
              <UploadZone
                copy={
                  <>
                    <Copy marginBottom={10}>Drag &rsquo;n&rsquo; drop your CVs, or click to select files</Copy>
                    <Copy marginBottom={30}>We accept MS Word, PDF & Richtext formats. </Copy>
                  </>
                }
                files={newFiles}
                setFiles={setNewFiles}
                onFileDrop={handleFileDrop}
              >
                {oldFilesList}
                {/* <pre>{JSON.stringify(newFiles, null, 2)}</pre> */}
                {/* <pre>{JSON.stringify(board?.files, null, 2)}</pre> */}
              </UploadZone>
            </WrapperInner>
            <WrapperButtons>
              <SecondaryButton
                disabled={isFileUploading}
                inline
                onClick={() => {
                  if (routerState?.from) {
                    navigate(routerState.from);
                  } else {
                    navigate(`/${Urls.Boards}`);
                  }
                }}
              >
                Cancel
              </SecondaryButton>
              <PrimaryButton
                iconRight={<ChevronRightIcon color={Colors.White} size={1} />}
                disabled={isFileUploading}
                type="submit"
                inline
                busy={false}
              >
                Update
              </PrimaryButton>
            </WrapperButtons>
          </WrapperRight>
        </Wrapper>
      </Form>
    );
  } else {
    child = <div key="error" />;
  }

  return (
    <>
      <Helmet title={`Update board - ${board?.title}`} />
      <UpdateWrapper>
        <H1>Update Board</H1>
        {child}
      </UpdateWrapper>
    </>
  );
};

const validationSchema = Yup.object().shape({
  title: Yup.string().max(70, 'The board title is too long!').required('This title is required'),
  linkedin: Yup.string().max(70, 'The linkedin url is too Long!'),
  availableDate: Yup.string(),
  description: Yup.string(),
  educationLevel: Yup.string(),
  interestLevel: Yup.string(),
});

type FormData = Yup.InferType<typeof validationSchema>;

const softwareEngineerSkills = [
  'Accessibility',
  'Adaptability',
  'Agile Methodologies',
  'Android Development',
  'Angular',
  'Ansible',
  'Apache Kafka',
  'Apache Spark',
  'Asana',
  'AWS (Amazon Web Services)',
  'Babel',
  'Blockchain Development',
  'Bootstrap',
  'C#',
  'Cassandra',
  'Chart.js',
  'Chai',
  'Code Review',
  'Collaboration',
  'Continuous Deployment',
  'Continuous Integration',
  'Critical Thinking',
  'Cypress',
  'Cybersecurity',
  'D3.js',
  'Database Design',
  'Data Modeling',
  'Debugging',
  'Django',
  'Docker',
  'Elasticsearch',
  'Enzyme',
  'ESLint',
  'Express.js',
  'Firebase',
  'Flask',
  'Git',
  'GitLab',
  'Go',
  'GraphQL',
  'Hadoop',
  'Haskell',
  'HTML/CSS',
  'iOS Development',
  'Java',
  'JavaScript',
  'Jenkins',
  'Jest',
  'JIRA',
  'JUnit',
  'Kanban',
  'Keras',
  'Kotlin',
  'Kubernetes',
  'Lua',
  'Machine Learning (ML)',
  'Maven',
  'Mercurial',
  'Microservices Architecture',
  'Microsoft Azure',
  'Mocha',
  'MongoDB',
  'Natural Language Processing (NLP)',
  'Next.js',
  'Node.js',
  'NoSQL',
  'npm',
  'OAuth',
  'Objective-C',
  'Oracle',
  'Pair Programming',
  'Pandas',
  'Perl',
  'Performance Testing',
  'PHP',
  'PostgreSQL',
  'Problem Solving',
  'Project Management',
  'Python',
  'PyTorch',
  'React.js',
  'Redis',
  'Responsive Web Design',
  'REST Assured',
  'RESTful APIs',
  'Ruby on Rails',
  'Rust',
  'Selenium',
  'Shell Scripting',
  'Slack',
  'Software Documentation',
  'Software Testing',
  'Spring Framework',
  'SQLite',
  'SQL',
  'Swift',
  'Teamwork',
  'Technical Writing',
  'TensorFlow',
  'Terraform',
  'Three.js',
  'Trello',
  'TypeScript',
  'UI/UX Design',
  'Unity',
  'Unix',
  'User Stories',
  'User-Centered Design',
  'Vagrant',
  'Version Control',
  'Vue.js',
  'WebSockets',
  'Webpack',
  'Windows Server',
  'Xamarin',
  'XML',
  'Yarn',
];
