export const boardFormProperties = {
  title: 'string',
  description: 'string',
  availableDate: 'date',
  location: 'string',
  locationStreet: 'string',
  locationPostCode: 'string',
  locationCity: 'string',
  locationCountry: 'string',
  locationUrl: 'string',
  locationLatitude: 'number',
  locationLongitude: 'number',
  educationLevel: 'enum',
  interestLevel: 'enum',
  industry: 'enum',
  linkedin: 'string',
  seniorityLevel: 'enum',
  workRightEU: 'boolean',
  workRightUK: 'boolean',
  coreSkills: 'string[]',
};

export const boardProperties = {
  ...boardFormProperties,
  id: 'key',
  relation: 'key',
  uuid: 'string',
  createdAt: 'datetime',
  updatedAt: 'datetime',
  isDeleted: 'boolean',
};
