import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationUpdateBoardArgs } from '../../generated/types';

export const UPDATE_BOARD = gql`
  mutation UpdateBoard($userUuid: String!, $boardUuid: String!, $data: BoardInputData!) {
    updateBoard(userUuid: $userUuid, boardUuid: $boardUuid, data: $data) {
      uuid

      title
      description
      linkedin
      availableDate
      location
      locationStreet
      locationPostCode
      locationCity
      locationCountry
      locationUrl
      locationLatitude
      locationLongitude
      educationLevel
      interestLevel
      seniorityLevel
      workRightEU
      workRightUK
      industry
      coreSkills

      files {
        uuid
        filename
        size
        mimetype
        formattedMimetype
        resource
        createdAt
        updatedAt
        isDeleted
        temporaryUrl
      }
      followers {
        uuid
        email
        nickname
        name
        avatar
      }

      isOwner
      owner {
        uuid
        email
        nickname
        name
      }

      createdAt
      createdBy {
        uuid
        email
        nickname
        name
      }
      updatedAt
      updatedBy {
        uuid
        email
        nickname
        name
      }

      isNew
      isDeleted
    }
  }
`;

export const useUpdateBoard = (options?: MutationHookOptions<Pick<Mutation, 'updateBoard'>, MutationUpdateBoardArgs>) =>
  useMutation<Pick<Mutation, 'updateBoard'>, MutationUpdateBoardArgs>(UPDATE_BOARD, {
    ...options,
  });
