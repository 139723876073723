import Autocomplete from '@mui/material/Autocomplete';
import { TextField, TextFieldProps } from '@mui/material';
import styled from 'styled-components';
import { Controller, useFormContext } from 'react-hook-form';

import { FieldWrapper } from '../FieldWrapper';
import { ErrorBoundary } from '../../../molecules/ErrorBoundary';

export const AutocompleteMultipleInput = ({
  name,
  options,
  noMargin,
  defaultValue,
  ...rest
}: AutocompleteInputProps) => {
  const { setValue, control } = useFormContext();

  return (
    <ErrorBoundary message="Atoms\Form\AutocompleteMultipleInput">
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue || []}
        render={({ field, fieldState }) => (
          <FieldWrapper noMargin={noMargin}>
            {/* <pre>{JSON.stringify({ field, fieldState }, null, 2)}</pre> */}
            {/* <pre>{JSON.stringify({ defaultValue }, null, 2)}</pre> */}

            <WrapperAutoComplete>
              <Autocomplete
                multiple
                freeSolo
                size="small"
                limitTags={3}
                options={options}
                defaultValue={defaultValue || []}
                onChange={(_event, newValue) => {
                  setValue(name, newValue);
                }}
                className={field.value.length > 4 ? 'isTall' : 'isNormal'}
                renderInput={(params) => (
                  <TextFieldExtended
                    {...params}
                    fullWidth
                    {...rest}
                    helperText={fieldState.error ? fieldState.error.message : ''}
                    color={fieldState.error ? 'error' : 'primary'}
                  />
                )}
                sx={{ width: '500px' }}
              />
            </WrapperAutoComplete>
          </FieldWrapper>
        )}
      />
    </ErrorBoundary>
  );
};

type AutocompleteInputProps = {
  options: string[];
  name: string;
  noMargin?: boolean;
} & TextFieldProps;

const WrapperAutoComplete = styled.div`
  .MuiAutocomplete-root {
    width: 100%;
  }

  .MuiInputBase-root {
    height: 52px;
  }

  .isTall {
    .MuiInputBase-root {
      height: 100px;
    }
  }
`;

const TextFieldExtended = styled(TextField)`
  .Mui-focused {
    fieldset {
      border-left-width: 6px !important; // override inline-style
    }
  }
  .MuiFormHelperText-contained {
    margin: 0;
  }
`;
